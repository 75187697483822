import { Controller } from "@hotwired/stimulus";
import { show, showBlock, hide } from '../../../utils';
import { setupTooltips } from "../../../bootstrap";

// Reference Financial Damages Form controller
export default class extends Controller {

  connect() {
    this.evictionFiled = this.element.querySelector('select[name="reference[eviction_filed]"]');
    this.evictionContextDiv = this.element.querySelector('#eviction-context-div');
    this.evictionContext = this.element.querySelector('#reference_eviction_note');
    this.evictionJudgment = this.element.querySelector('select[name="reference[eviction_judgment]"]');
    this.evictionJudgmentDateDiv = this.element.querySelector('#eviction-judgment-date-div');
    this.balanceOwed = this.element.querySelector('#reference_balance_past_due');
    this.balanceOwedNoteDiv = this.element.querySelector('#balance-owed-note-div');
    this.balanceOwedNote = this.element.querySelector('#reference_balance_past_due_note');
    this.propertyDamage = this.element.querySelector('select[name="reference[property_damage]"]');
    this.propertyDamageNoteDiv = this.element.querySelector('#property-damage-note-div');
    this.propertyDamageNote = this.element.querySelector('#reference_property_damage_note');

    this.evictionFiled.addEventListener("change", this.evictionFiledEventListener.bind(this));
    this.evictionJudgment.addEventListener("change", this.evictionJudgmentEventListener.bind(this));
    this.balanceOwed.addEventListener("input", this.balanceOwedEventListener.bind(this));
    this.propertyDamage.addEventListener("change", this.propertyDamageEventListener.bind(this));
    this.syncFormWithEvictionFiled();
    this.syncFormWithBalanceOwed();
    this.syncFormWithPropertyDamage();
    this.syncFormWithEvictionJudgment();
    setupTooltips();
  }

  evictionFiledEventListener(e) {
    this.syncFormWithEvictionFiled();
  }

  evictionJudgmentEventListener(e) {
    this.syncFormWithEvictionJudgment();
  }

  balanceOwedEventListener() {
    this.syncFormWithBalanceOwed();
  }

  propertyDamageEventListener() {
    this.syncFormWithPropertyDamage();
  }

  syncFormWithBalanceOwed() {
    let value = this.balanceOwed.value;
    if (value > 0) {
      showBlock(this.balanceOwedNoteDiv);
      this.balanceOwedNote.required = true;

    } else {
      hide(this.balanceOwedNoteDiv);
      this.balanceOwedNote.required = false;
    }
  }

  syncFormWithPropertyDamage() {
    let value = this.propertyDamage.value;
    if (value === 'MINOR_DAMAGE' || value === 'MAJOR_DAMAGE') {
      showBlock(this.propertyDamageNoteDiv);
      this.propertyDamageNote.required = true;
    } else {
      hide(this.propertyDamageNoteDiv);
      this.propertyDamageNote.required = false;
    }
  }

  syncFormWithEvictionFiled() {
    let value = this.evictionFiled.value;
    if (value === 'true') {
      showBlock(this.evictionContextDiv);
      this.evictionContext.required = true;
    } else {
      hide(this.evictionContextDiv);
      this.evictionContext.required = false;
    }
  }

  syncFormWithEvictionJudgment() {
    let value = this.evictionJudgment.value;
    if (value === 'true') {
      showBlock(this.evictionJudgmentDateDiv);
    } else {
      hide(this.evictionJudgmentDateDiv);
    }
  }
}
