import { Controller } from "@hotwired/stimulus";
import {
  templatedEvents,
  toggleScriptFormVisibility,
  toggleCallResultSelectVisibility,
  setupOutcomeDropdownChoiceManagement,
} from "../../../../../frontend/javascript/utils/outreach_events";

export default class extends Controller {
  connect() {
    this.scriptFormContainer = this.element.querySelector("#outreach_events_reference_script_form_container");
    this.contactMethodDropdown = this.element.querySelector("#outreach_event_reference_contact_method");
    this.directionDropdown = this.element.querySelector("#outreach_event_reference_direction");
    this.outcomeOptionsDropdown = this.element.querySelector("#outreach_event_reference_outcome");
    this.templateDropdown = this.element.querySelector("#outreach_event_reference_template_used");
    this.contactResultDropdown = this.element.querySelector("#outreach_event_reference_contact_result");
    this.contactResultDropdownWrapper = this.element.querySelector(
      "#outreach_event_reference_contact_result_select_wrapper",
    );

    toggleScriptFormVisibility(this.scriptFormContainer, this.contactMethodDropdown);
    this.toggleSendReferenceInitialOutreach();
    toggleCallResultSelectVisibility(
      this.contactResultDropdown,
      this.contactResultDropdownWrapper,
      this.contactMethodDropdown,
    );
    setupOutcomeDropdownChoiceManagement(
      this.outcomeOptionsDropdown,
      this.contactMethodDropdown,
      this.directionDropdown,
      this.contactResultDropdown,
    );
    this.setupAttachedFileDropzoneManagement();
    this.setupTemplatedEventsExperience();
  }
  
  setupTemplatedEventsExperience() {
    function getRenderedMessageIds() {
      let element = document.getElementById("rendered-message-ids");
      let renderedMessageIds = JSON.parse(element.getAttribute("data-rendered-message-ids"));
      return renderedMessageIds;
    }

    function getTemplateMetadata() {
      let element = document.getElementById("message-template-metadata");
      let templateMetadata = JSON.parse(element.getAttribute("data-message-template-metadata"));
      return templateMetadata;
    }

    const toggleTemplatedEventsExperience = () => {
      if (templatedEvents().includes(this.contactMethodDropdown.value) && this.directionDropdown.value === "OUTBOUND") {
        document.getElementById("templated-events-experience").hidden = false;
        this.templateDropdown.required = true;
      } else {
        document.getElementById("templated-events-experience").hidden = true;
        this.templateDropdown.required = false;
        this.templateDropdown.value = null;
      }
    };

    const showCorrectMessageTemplate = () => {
      let renderedMessageIds = getRenderedMessageIds();
      let idToShow = this.contactMethodDropdown.value?.toLowerCase() + "_" + this.templateDropdown.value;
      for (let i = 0; i < renderedMessageIds.length; i++) {
        if (renderedMessageIds[i] === idToShow) {
          document.getElementById(renderedMessageIds[i]).hidden = false;
        } else {
          document.getElementById(renderedMessageIds[i]).hidden = true;
        }
      }
    };

    const selectCorrectOutcomeBasedOnTemplateUsed = () => {
      let templateMetdata = getTemplateMetadata();
      if (
        templateMetdata[this.templateDropdown.value] &&
        templateMetdata[this.templateDropdown.value].expected_outcome
      ) {
        this.outcomeOptionsDropdown.value = templateMetdata[this.templateDropdown.value].expected_outcome;
      }
    };

    this.templateDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      selectCorrectOutcomeBasedOnTemplateUsed();
    });
    this.contactMethodDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleTemplatedEventsExperience();
    });
    this.directionDropdown.addEventListener("change", (e) => {
      showCorrectMessageTemplate();
      toggleTemplatedEventsExperience();
    });
    showCorrectMessageTemplate();
    toggleTemplatedEventsExperience();
    selectCorrectOutcomeBasedOnTemplateUsed();
  }

  toggleSendReferenceInitialOutreach() {
    const referenceEmailInput = this.element.querySelector("#reference_email");
    const phoneInput = this.element.querySelector("#reference_phone");
    const smsEnabledInput = this.element.querySelector("#reference_phone_sms_enabled");
    const sendContactEmailCheckbox = this.element.querySelector("#send_reference_contact_email");
    const toggleSendInitialOutreach = () => {
      let email = document.getElementById("reference_email").value;
      let phoneNumber = document.getElementById("reference_phone").value;
      let smsEnabled = document.getElementById("reference_phone_sms_enabled").value;
      if (this.sendContactEmailCheckbox) {
        sendContactEmailCheckbox.checked = false;
        sendContactEmailCheckbox.disabled = !(email || (phoneNumber && smsEnabled === "true"));
      }
    };
    referenceEmailInput?.addEventListener("input", (e) => {
      toggleSendInitialOutreach();
    });
    phoneInput?.addEventListener("input", (e) => {
      toggleSendInitialOutreach();
    });
    smsEnabledInput?.addEventListener("change", (e) => {
      toggleSendInitialOutreach();
    });
    this.contactMethodDropdown.addEventListener("change", (_e) => {
      toggleSendInitialOutreach();
    });
  }

  setupAttachedFileDropzoneManagement() {
    let dropzone = document.getElementById("attached_file_dropzone");
    this.outcomeOptionsDropdown.addEventListener("change", (e) => {
      manageDropzone(e.target.value);
    });

    manageDropzone(this.outcomeOptionsDropdown.value);

    function manageDropzone(selectedOutcome) {
      if (selectedOutcome == "RECEIVED_LEDGER") {
        showDropzone();
      } else {
        hideDropzone();
      }
    }

    function showDropzone() {
      dropzone.hidden = false;
    }

    function hideDropzone() {
      dropzone.hidden = true;
    }
  }
}
