import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";
import "./component.scss";

// Modal controller
export default class extends Controller {
  static targets = ["duplicateOrders"];

  duplicateOrdersTargetConnected() {
    this.modal = Modal.getInstance(this.getModalElement());
    this.open();
  }

  connect() {
    const modal = this.getModalElement();
    this.modal = new Modal(modal);
    modal.addEventListener("turbo:frame-missing", (event) => {
      if (event.detail.response.redirected) {
        event.preventDefault();
        event.detail.visit(event.detail.response);
      }
    });
  }

  getModalElement() {
    return document.querySelector("#application_modal");
  }

  open() {
    const modal = this.getModalElement();
    const modalDialog = modal.querySelector(".modal-dialog");
    const modalContent = modal.querySelector(".modal-content");
    const modalBody = modal.querySelector(".modal-body");
    const modalSize = modalBody?.dataset.size;

    // Dynamically set modal width by data attribute (if present) modal body
    if (modalSize) {
      switch (modalSize) {
        case "full":
          modalDialog.classList.add(`modal_full_width`);
          break;
        case "responsive":
          ["modal_width_responsive", "m-0", "p-4"].forEach((classname) => modalDialog.classList.add(classname));

          ["w-auto", "mx-auto"].forEach((classname) => modalContent.classList.add(classname));
          break;
        default:
          modalDialog.classList.add(`modal-${modalBody.dataset.size}`);
      }
    }

    if (!this.modal.isOpened) {
      this.modal.show();
    }
  }

  close(event) {
    // End early if submit button has closeModal set to false. Useful for modals with multiple forms.
    const closeModal = event.detail.formSubmission.submitter.dataset.closeModal;
    if (closeModal === "false") return;

    let backdrop = document.querySelector(".modal-backdrop");
    if (event.detail.success) {
      this.modal.hide();

      backdrop && backdrop.remove();
    }
  }
}
