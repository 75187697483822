import { Controller } from "@hotwired/stimulus";
import { showBlock, hide } from '../../../utils';
import { setupTooltips } from "../../../bootstrap";

// Reference Residence Summary Form controller
export default class extends Controller {

  connect() {
    this.wouldRentAgain = this.element.querySelector('select[name="reference[would_rent_again]"]');
    this.numberOfAnimals = this.element.querySelector('#reference_number_of_animals');
    this.animalsNoteDiv = this.element.querySelector('#animals-note-div');
    this.animalsNote = this.element.querySelector('#reference_animals_note');
    this.wouldNotRentAgainNoteDiv = this.element.querySelector('#would-not-rent-again-note-div');
    this.wouldNotRentAgainNote = this.element.querySelector('#reference_would_not_rent_again_note');

    this.wouldRentAgain.addEventListener("change", this.wouldRentAgainEventListener.bind(this));
    this.numberOfAnimals.addEventListener("input", this.numberOfAnimalsEventListener.bind(this));

    this.syncFormWithNumberOfAnimals();
    this.syncFormWithWouldRentAgain();
    setupTooltips();
  }

  numberOfAnimalsEventListener(e) {
    this.syncFormWithNumberOfAnimals();
  }

  wouldRentAgainEventListener(e) {
    this.syncFormWithWouldRentAgain();
  }

  syncFormWithNumberOfAnimals() {
    let value = this.numberOfAnimals.value;
    if (value > 0) {
      showBlock(this.animalsNoteDiv);
      this.animalsNote.required = true;
    } else {
      hide(this.animalsNoteDiv);
      this.animalsNote.required = false;
    }
  }

  syncFormWithWouldRentAgain() {
    let value = this.wouldRentAgain.value;
    if (value === 'false') {
      showBlock(this.wouldNotRentAgainNoteDiv);
      this.wouldNotRentAgainNote.required = true;
    } else {
      hide(this.wouldNotRentAgainNoteDiv);
      this.wouldNotRentAgainNote.required = false;
    }
  }
}
